<script>
import Layout from '@layouts/main.vue'
import axios from 'axios'
import { authMethods } from '@state/helpers'

export default {
  page() {
    return {
      title: this.user.name,
      meta: [
        {
          name: 'description',
          content: `The user profile for ${this.user.name}.`,
        },
      ],
    }
  },
  components: { Layout },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiuser: {},
    }
  },
  mounted() {
    this.refresh()
      .then((data) => {
        axios.get('user').then(
          (res) => (this.apiuser = res.data),
          (error) => (this.apiuser = error)
        )
      })
      .catch((error) => {
        // quelque chose a faire si le refresh fail
        if (error) {
          this.apiuser = 'failed to refresh access token'
        }
      })
  },
  methods: {
    ...authMethods,
  },
}
</script>

<template>
  <Layout>
    <h1> <BaseIcon name="user" />{{ user.name }} </h1>
    <h2>Cognito Profile</h2>
    <pre>{{ user }}</pre>
    <h2>Users Profile</h2>
    <pre>{{ apiuser }}</pre>
    <h2>Refresh Cognito Token</h2>
    <button @click="refresh">refresh</button>
  </Layout>
</template>
